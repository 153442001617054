/* generated using https://google-webfonts-helper.herokuapp.com/fonts/roboto?subsets=latin */
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/roboto-v29-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/roboto-v29-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/roboto-v29-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/roboto-v29-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
